<template>
  <transition name="notification">
    <div
      class="absolute z-[10000] max-w-[40rem] flex items-center border justify-center top-4 right-4 gap-2 bg-popover shadow-lg py-2 px-4 rounded-md text-sm"
      v-if="notification.show"
      :class="notificationClasses"
      @click="closeNotification"
    >
      <badge-check
        v-if="notification.type == 'success'"
        :size="16"
      />
      <ban-icon
        v-if="notification.type == 'error'"
        :size="16"
      />
      <badge-alert
        v-if="notification.type == 'warning'"
        :size="16"
      />
      <div class="message">
        {{ notification.message }}
      </div>
    </div>
  </transition>
</template>

<script>
import { notifyStore } from '@/stores/notification';
import { BadgeCheck, BadgeAlert, Ban } from 'lucide-vue';

export default {
  components: {
    BadgeCheck,
    BadgeAlert,
    BanIcon: Ban
  },
  data() {
    return {
      show: false,
      message: '',
      type: '',
      timeout: null
    };
  },
  setup() {
    const notification = notifyStore();
    return { notification };
  },
  computed: {
    notificationClasses: function () {
      const typeClasses = {
        error: 'border-red-100 bg-red-50 text-red-600',
        warning: 'border-orange-100 bg-orange-50 text-orange-600'
      };
      return typeClasses[this.notification.type] || 'border-green-50 bg-green-50 text-green-600';
    }
  },
  methods: {
    closeNotification() {
      this.notification.closeNotification();
    }
  }
};
</script>
<style scoped>
.notification-enter-active,
.notification-leave-active {
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.notification-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.notification-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

</style>
