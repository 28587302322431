import axios from '@/config/services/http';

export default {
  // Register Page
  registerPartner: (data) => {
    return axios.post('/api/register', data);
  },
  confirmRegistration: (applicationId, hash, expires, signature) => {
    return axios.post(`/api/email/verify/${applicationId}/${hash}`, {
      params: { expires, signature }
    });
  },
  resendMail: () => {
    return axios.post('/api/email/resend');
  },
  fetchUserDetails: () => {
    return axios.get('/api/user');
  },

  // Login Page
  loginUser: (data) => {
    return axios.post('/api/login', data);
  },

  // Login Page
  logoutUser: () => {
    return axios.post('/api/logout', {});
  },

  // Forgot Password Page
  forgotPassword: (data) => {
    return axios.post('/api/password/email', data);
  },
  resetPasswordAuth: (data) => {
    return axios.post('/api/password/reset', data);
  },

  // Plaid IDV and Bank Account Linking endpoints
  linkUnderWritingAccount: (data) => {
    return axios.post('/api/plaid/link/underwriting-account', data);
  },
  createLinkTokenUnderWritingAccount: () => {
    return axios.post('/api/plaid/create-link-token/underwriting-account');
  },
  createLinkedTokenFundingAccount: () => {
    return axios.post('/api/plaid/create-link-token/funding-account');
  },
  linkFundingAccount: (data) => {
    return axios.post('/api/plaid/link/funding-account', data);
  },
  createLinkedTokenIdentityVerification: (data) => {
    return axios.post(
      '/api/plaid/create-link-token/identity-verification',
      data
    );
  },

  getFeatureToggles: () => {
    return axios.get('/api/feature-toggles');
  },

  // Documents
  getApplicationDocumentTypes: () => {
    return axios.get('/api/applications/document-types');
  },
  getApplicationDocuments: () => {
    return axios.get('/api/applications/documents');
  },
  deleteApplicationDocuments: (documentId) => {
    return axios.delete(`/api/applications/documents/${documentId}`);
  },

  // ACCOUNT SETTINGS PAGES

  // Personal Settings (External Api-Key)
  externalApiKey: () => {
    return axios.get('/api/api-key');
  },
  // Users Settings
  saveSubUserDetails: (data) => {
    return axios.post('/api/account-management/users', data);
  },
  getSubUsers: () => {
    return axios.get('/api/account-management/users');
  },
  getOwners: () => {
    return axios.get('/api/account-management/owners');
  },
  getAccountUser: (id) => {
    return axios.get(`/api/account-management/users/${id}`);
  },
  deleteSubUser: (id) => {
    return axios.delete(`/api/account-management/users/${id}`);
  },
  // Business Settings
  getCompanyInfo: () => {
    return axios.get('/api/company');
  },
  // Payments Settings
  getCardOverview: () => {
    return axios.get('/api/card/overview');
  },
  getFundingAccount: () => {
    return axios.get('/api/plaid/funding-account');
  },
  getUnderwritingAccount: () => {
    return axios.get('/api/plaid/underwriting-account');
  },
  // Connected Services + Accounting Platform Settings
  getConnectorsBusinessName: () => {
    return axios.get('/api/data/connectors/business_name');
  },
  getConnectorsAds: () => {
    return axios.get('/api/data/connectors/ads');
  },
  saveConnectors: (data) => {
    return axios.post('/api/data/connectors', data);
  },
  getConnectorSales: () => {
    return axios.get('/api/data/connectors/sales');
  },
  getConnectorsAccounting: () => {
    return axios.get('/api/data/connectors/accounting');
  },
  // One Click Credit Settings
  getCompanyStakeholders: () => {
    return axios.get('/api/company/stakeholders');
  },
  saveConsent: (data) => {
    return axios.post('/api/company/one-click-credit-consent', data);
  },
  // Reset Password Settings
  resetPassword: (data) => {
    return axios.post('/api/reset-password', data);
  },

  // ADCARD ONE PAGES

  // Rewards
  getCryptoRewardsStatus: () => {
    return axios.get('/api/crypto-rewards');
  },
  toggleUserWallet: (data) => {
    return axios.put('/api/crypto-rewards', data);
  },
  getRewardsOverview: () => {
    return axios.get('/api/rewards/overview');
  },
  updateRewardsOption: (data) => {
    return axios.post('/api/rewards/update-option', data);
  },
  getRewardsPayments: (data) => {
    return axios.get('/api/rewards/payments', data);
  },
  getRewardsBalance: () => {
    return axios.get('/api/rewards/balances');
  },
  listAllCards: (data) => {
    return axios.get('/api/rewards/cards', data);
  },
  requestRewardCard: (data) => {
    return axios.post('/api/rewards/cards/email-request', data);
  },
  updateRewardCard: (id, data) => {
    return axios.put(`/api/rewards/cards/${id}`, data);
  },
  freezeCard: (id, data) => {
    return axios.post(`/api/rewards/cards/${id}/freeze`, data);
  },
  unfreezeCard: (id, data) => {
    return axios.post(`/api/rewards/cards/${id}/resume`, data);
  },
  deleteCard: (id, data) => {
    return axios.post(`/api/rewards/cards/${id}/archive`, data);
  },
  showCard: (id, data) => {
    return axios.get(`/api/rewards/cards/${id}`, data);
  },
  shareCard: (id, data) => {
    return axios.post(`/api/rewards/cards/${id}/temp-details-url`, data);
  },
  showAllTransactions: (data) => {
    return axios.get('/api/rewards/transactions', data);
  },
  downloadcsvTransactions: (data) => {
    return axios.post('/api/rewards/transactions/csv', data, {
      responseType: 'blob'
    });
  },
  downloadpdfTransactions: (data) => {
    return axios.post('/api/rewards/transactions/pdf', data, {
      responseType: 'blob'
    });
  },
  downloadFilteredCsvTransactions: (data) => {
    return axios.post('/api/rewards/transactions/filtered-csv', data, {
      responseType: 'blob'
    });
  },
  downloadFilteredPdfTransactions: (data) => {
    return axios.post('/api/rewards/transactions/filtered-pdf', data, {
      responseType: 'blob'
    });
  },
  getTempUrl: (data) => {
    return axios.get(data);
  },
  saveCardPin: (id, data) => {
    return axios.post(`/api/rewards/cards/${id}/pin-setup`, data);
  },
  getRewardRedeems: (data) => {
    return axios.get('/api/rewards/redeems', data);
  },
  saveRedemptionAmount: (data) => {
    return axios.post('/api/rewards/redeems', data);
  },

  // Insights
  getAllCards: (data) => {
    return axios.get('/api/card/cards', data);
  },

  // Transactions & Payments
  searchDailyCombinedPayments: (data, config) => {
    return axios.post('/api/dash-payments/payments/daily-combined-payments/search', data, config);
  },
  searchTransactions: (data) => {
    return axios.post('/api/dash-payments/payments/transactions/search', data);
  },
  searchPayments: (data, config) => {
    return axios.post('/api/dash-payments/payments/search', data, config);
  },
  reschedulePayment: (data) => {
    return axios.post('/api/dash-payments/payments/reschedule', data);
  },
  getAllTransactions: (data) => {
    return axios.get('/api/card/transactions', data);
  },
  getCashTransactions: (data) => {
    return axios.get('/api/card/v2/cash', data);
  },
  transactionsPdfStatements: (data) => {
    return axios.post('/api/card/transactions-statement', data, {
      responseType: 'blob'
    });
  },
  transactionsExcelStatements: (data) => {
    return axios.post('/api/card/transactions-statement-excel', data, {
      responseType: 'blob'
    });
  },
  cashExcelStatements: (data) => {
    return axios.post('/api/card/v2/cash/excel', data, {
      responseType: 'blob'
    });
  },
  refreshAllTransactions: (data) => {
    return axios.post('/api/card/transactions/refresh', data);
  },
  transactionsFilteredCsvStatementAdcardOne: (data) => {
    return axios.post('/api/card/transactions-statement-filtered', data, {
      responseType: 'blob'
    });
  },

  transactionsFilteredPdfStatementAdcardOne: (data) => {
    return axios.post('/api/card/transactions-statement-pdf-filtered', data, {
      responseType: 'blob'
    });
  },

  // dash-payments
  getE6Payments: (data) => {
    return axios.get('/api/dash-payments/payments', data);
  },

  getE6PaymentsPdfStatements: (data) => {
    return axios.post('/api/dash-payments/statement-pdf', data, {
      responseType: 'blob'
    });
  },

  getE6FilteredPaymentsPdfStatements: (data) => {
    return axios.post('/api/dash-payments/statement-pdf-filtered', data, {
      responseType: 'blob'
    });
  },

  getE6PaymentsCsvStatements: (data) => {
    return axios.post('/api/dash-payments/statement-csv', data, {
      responseType: 'blob'
    });
  },

  getE6FilteredPaymentsCsvStatements: (data) => {
    return axios.post('/api/dash-payments/statement-csv-filtered', data, {
      responseType: 'blob'
    });
  },

  // card-transactions
  getE6Transactions: (data) => {
    return axios.get('/api/card-transactions/transactions', data);
  },

  getE6TransactionsPdfStatements: (data) => {
    return axios.post('/api/card-transactions/statement-pdf', data, {
      responseType: 'blob'
    });
  },

  getE6FilteredTransactionsPdfStatements: (data) => {
    return axios.post('/api/card-transactions/statement-pdf-filtered', data, {
      responseType: 'blob'
    });
  },

  getE6TransactionsCsvStatements: (data) => {
    return axios.post('/api/card-transactions/statement-csv', data, {
      responseType: 'blob'
    });
  },

  getE6FilteredTransactionsCsvStatements: (data) => {
    return axios.post('/api/card-transactions/statement-csv-filtered', data, {
      responseType: 'blob'
    });
  },


  // Cards
  getCardsWithoutSpending: (data) => {
    return axios.get('/api/card/cards-without-spending', data);
  },
  getCardCategories: () => {
    return axios.get('/api/card/cards-categories');
  },
  getCardTypeCategories: () => {
    return axios.get('/api/card/card-type-categories');
  },
  requestDailyLimitIncrease: (data) => {
    return axios.post('/api/card/request-daily-limit-increase', data);
  },
  saveCards: (data) => {
    return axios.post('/api/card/cards', data);
  },
  updateCards: (id, data) => {
    return axios.post(`/api/card/cards/update/${id}`, data);
  },
  getCards: (id) => {
    return axios.get(`/api/card/cards/${id}`);
  },
  deleteCards: (id) => {
    return axios.delete(`/api/card/cards/${id}`);
  },
  freezeCards: (id) => {
    return axios.post(`/api/card/cards/freeze/${id}`);
  },
  resumeCards: (id) => {
    return axios.post(`/api/card/cards/resume/${id}`);
  },
  getAdcardV2Insights: (params) => {
    return axios.get('/api/card/v2/insights', params);
  },
  getAdcardV2InsightsGraph: (params) => {
    return axios.get('/api/card/v2/insights/graph', params);
  },

  // ADPAY PAGES
  getConsolidatedPayments: (data) => {
    return axios.get('/api/pay/consolidated-payments', data);
  },
  getInvoices: (data) => {
    return axios.get('/api/pay/invoices', data);
  },
  getInvoicesById: (id) => {
    return axios.get(`/api/pay/invoices/${id}`);
  },
  getAdpayOverview: () => {
    return axios.get('/api/pay/overview');
  },
  getDataFromCustomUrl: (url) => {
    return axios.get(url);
  },

  // ADCARD TWO PAGES

  getPrepaidCards: (data) => {
    return axios.get('/api/card/v2/cards', data);
  },
  getPrepaidCardOverview: () => {
    return axios.get('/api/card/v2/overview');
  },
  getPrepaidCardTypeCategories: () => {
    return axios.get('/api/card/v2/card-type-categories');
  },
  savePrepaidCards: (data) => {
    return axios.post('/api/card/v2/cards', data);
  },
  updatePrepaidCards: (id, data) => {
    return axios.put(`/api/card/v2/cards/${id}`, data);
  },
  getPrepaidCard: (id) => {
    return axios.get(`/api/card/v2/cards/${id}`);
  },
  deletePrepaidCards: (id) => {
    return axios.post(`/api/card/v2/cards/${id}/archive`);
  },
  freezePrepaidCards: (id) => {
    return axios.post(`/api/card/v2/cards/${id}/freeze`);
  },
  resumePrepaidCards: (id) => {
    return axios.post(`/api/card/v2/cards/${id}/resume`);
  },

  getFinancialAccount: () => {
    return axios.get('/api/card/v2/financial-account');
  },
  getWireDetailsPdfStatement: () => {
    return axios.get('/api/card/v2/financial-account/download-wire-details', {
      responseType: 'blob'
    });
  },

  // New Onboarding Pages
  getApplicationDetails: () => {
    return axios.get('/api/v2/applications');
  },
  postApplicationIdv: (id, data) => {
    return axios.post(`/api/v2/applications/${id}/process-kyc`, data);
  },
  getApplicationIdvStatus: (id, data) => {
    return axios.post(`api/v2/applications/${id}/kyc-status`, data);
  },
  // Terms and Conditions Endpoints
  saveApplicationConsent: (id) => {
    return axios.post(`/api/v2/applications/${id}/consent`);
  },
  // Business Details Endpoints
  saveBusinessDetails: (id, data) => {
    return axios.put(`/api/v2/applications/${id}/company`, data);
  },
  saveSpendingRange: (id, data) => {
    return axios.put(`/api/v2/applications/${id}/spending-range`, data);
  },
  // Beneficiaries Endpoints
  updateAdminShareRole: (id, data) => {
    return axios.put(`/api/v2/applications/${id}/admin-share-role`, data);
  },
  setBeneficiaries: (id, data) => {
    return axios.post(`/api/v2/applications/${id}/beneficial_owners`, data);
  },
  deleteBeneficiary: (id, beneficiaryId) => {
    return axios.delete(
      `/api/v2/applications/${id}/beneficial_owners/${beneficiaryId}`
    );
  },
  // Controlling Officers Endpoints
  setControllingOfficer: (id, data) => {
    return axios.post(`/api/v2/applications/${id}/controlling_officer`, data);
  },
  deleteControllingOfficer: (id, officerId) => {
    return axios.delete(
      `/api/v2/applications/${id}/controlling_officer/${officerId}`
    );
  },
  // Activation Page Endpoints
  registerFirstAccess: (id) => {
    return axios.post(`/api/v2/applications/${id}/register-first-access`);
  },
  submitApplication: (id) => {
    return axios.post(`/api/v2/applications/${id}/submit`);
  },

  // Document Upload Endpoints
  getDocumentTypes: () => {
    return axios.get('/api/v2/applications/document-types');
  },
  getDocuments: () => {
    return axios.get('/api/v2/applications/documents');
  },
  uploadDocument: (docType, data) => {
    return axios.post(`/api/v2/applications/documents/${docType} `, data);
  },
  deleteDocument: (documentId) => {
    return axios.delete(`/api/v2/applications/documents/${documentId}`);
  },
  getDocument: (documentId) => {
    return axios.get(`/api/v2/applications/documents/${documentId}`);
  },

  // Sardine
  riskCheck: () => {
    return axios.post('/api/risk/check');
  },

  // E6 Endpoints
  // dash-cards
  accountOverview: () => {
    return axios.get('/api/dash-cards/overview');
  },
  setLegacyAccountState: (data) => {
    return axios.post('/api/dash-cards/set-legacy-account-state', data);
  },
  getCardProfiles: () => {
    return axios.get('/api/dash-cards/card-profiles');
  },
  createE6Card: (data) => {
    return axios.post('/api/dash-cards/card', data);
  },
  listAllE6Cards: (data) => {
    return axios.get('/api/dash-cards/cards', data);
  },
  getE6Card: (id) => {
    return axios.get(`/api/dash-cards/card/${id}`);
  },
  displayE6Card: (id) => {
    return axios.post(`/api/dash-cards/card/${id}/embed-url`);
  },
  e6CardPan: (url, data) => {
    return axios.post(url, data);
  },
  freezeE6Cards: (id) => {
    return axios.put(`/api/dash-cards/card/${id}/freeze`);
  },
  unfreezeE6Cards: (id) => {
    return axios.put(`/api/dash-cards/card/${id}/activate`);
  },
  deleteE6Cards: (id) => {
    return axios.put(`/api/dash-cards/card/${id}/archive`);
  },
  updateE6Card: (id, data) => {
    return axios.put(`/api/dash-cards/card/${id}`, data);
  },

  // Google 2FA
  get2FAChallenge: () => {
    return axios.get('/api/2fa/challenge');
  },
  enable2FAToken: (data) => {
    return axios.post('/api/2fa/verify', data);
  },
  verify2FALogin: (data) => {
    return axios.post('/api/code', data);
  },

  // Customer data
  getCustomerDeposits: () => {
    return axios.get(`/api/treasury/deposits`);
  },

  getContractsRebate: () => {
    return axios.get('/api/contracts/rebate');
  },

  // ComplyCo docs
  getComplyCoToken: async () => {
    try {
      const response = await axios.post('/api/contracts/complyco-token');
      return Promise.resolve({ token: response.data.token });
    } catch(err) {
      console.log('error', err);
    }
  }

};
