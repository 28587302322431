<template>
  <div class="z-full">
    <transition name="fade">
      <div
        v-if="warningZone"
        class="fixed top-0 left-0 flex items-center justify-center h-screen w-screen z-50 bg-black-700 bg-opacity-50"
      >
        <div
          v-on-clickaway="toggle"
          class="absolute overflow-y-auto w-screen max-h-3/4 md:w-96 rounded border border-gray-300 shadow-md bg-white p-6"
        >
          <div class="flex justify-end">
            <img
              @click="closeDialog"
              class="w-4 cursor-pointer"
              src="@/assets/images/svg/icon-close.svg"
              alt="close icon"
            />
          </div>

          <h3 class=" font-bold text-2xl mb-6 text-center">
            Session Timeout
          </h3>

          <div class="flex justify-center">
            <count-down-ring :remaining-time="remainingTime" />
          </div>

          <p class=" font-normal text-base mb-6 text-center">
            Your session is about to timeout and will expire in
            {{ secondsToHms(remainingTime) }}.
          </p>

          <div class="flex justify-center">
            <df-button
              @click="resetTimer"
              class="text-center"
              type="submit"
              :loading="loading"
            >
              Keep me logged in
            </df-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';
import { mixin as clickaway } from 'vue-clickaway2';
import DfButton from '@/components/ui/df-button.vue';
import CountDownRing from '@/components/common/auto-logout/count-down-ring.vue';
import { authStore } from '@/stores/auth';
import * as Sentry from '@sentry/vue';

export default {
  name: 'AutoLogout',
  mixins: [clickaway],
  components: { DfButton, CountDownRing },
  setup() {
    const auth = authStore();
    return { auth };
  },
  computed: {
    loggedIn() {
      return Boolean(this.auth.isAuthenticated);
    },
    dfSession() {
      return this.getCookie('df-session');
    }
  },
  data() {
    return {
      loading: false,

      warningTimer: null,
      logoutTimer: null,
      remainingTimer: null,

      warningZone: false,

      remainingTime: 0,
      warningTimeout: 0,
      sessionTimeout: 0
    };
  },
  watch: {
    remainingTime: {
      handler(value) {
        if (value > 0) {
          this.remainingTimer = setTimeout(() => {
            this.remainingTime--;
          }, 1000);
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.loggedIn && this.checkCookieValidity() === 1) {
      this.calcTimeout();
      this.setTimers();
    }
  },
  destroyed() {
    this.resetTimer();
  },
  methods: {
    checkCookieValidity() {
      const decodedSession = decodeURIComponent(this.dfSession);
      const cookieDate = moment(decodedSession);

      return Math.sign(
        Math.round(moment.duration(cookieDate.diff(moment())).as('seconds'))
      );
    },
    toggle() {
      this.warningZone = !this.warningZone;
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      let hDisplay = '';
      let mDisplay = '';
      let sDisplay = '';

      if (h > 0) {
        hDisplay = h + (h === 1 ? ' hour, ' : ' hours, ');
      }

      if (m > 0) {
        mDisplay = m + (m === 1 ? ' minute, ' : ' minutes, ');
      }

      if (s > 0) {
        sDisplay = s + (s === 1 ? ' second' : ' seconds');
      }

      return hDisplay + mDisplay + sDisplay;
    },

    closeDialog() {
      this.warningZone = false;
    },
    calcTimeout() {
      const decodedSession = decodeURIComponent(this.dfSession);
      const returnedDate = moment(decodedSession);
      const timeDifference = Math.round(
        moment.duration(returnedDate.diff(moment())).as('seconds')
      );

      this.remainingTime = timeDifference;
      this.sessionTimeout = timeDifference * 1000;
      this.warningTimeout = (timeDifference - 60) * 1000;
    },
    getCookie(cname) {
      let cookie = {};
      document.cookie.split(';').forEach(function (el) {
        let split = el.split('=');
        cookie[split[0].trim()] = split.slice(1).join('=');
      });
      return cookie[cname];
    },
    setTimers() {
      this.warningTimer = setTimeout(() => {
        this.warningZone = true;
      }, this.warningTimeout);

      this.logoutTimer = setTimeout(() => {
        Sentry.setUser(null);
        this.$router.push('/logout');
      }, this.sessionTimeout);

      this.warningZone = false;
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      clearTimeout(this.remainingTimer);

      this.setTimers();
      window.location.reload();
    }
  }
};
</script>
