<template>
  <div
    class="loader bg-gray-900"
    :class="{
      'absolute top-1/2 left-1/2 ui-center': !inline,
      'relative': inline,
    }"
    :style="{
      '--_width': size === 'sm' ? '16px' : size === 'md' ? '24px' : '32px',
      '--_height': size === 'sm' ? '4px' : size === 'md' ? '6px' : '8px',
    }"
  ></div>
</template>
<script>
export default {
  name: 'LoaderComponent',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  }
};
</script>
<style scoped>
.loader {
  --_width: 24px;
  --_height: 6px;
  height: var(--_height);
  width: var(--_width);
  border-radius: 2px;
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function:  all 600ms cubic-bezier(1, 0, 0, 1);
}

.ui-center {
  margin-left: calc(var(--_width) / -2);
  margin-top: calc(var(--_height) / -2);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(730deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
</style>
