import { render, staticRenderFns } from "./df-button.vue?vue&type=template&id=cfd35efe"
import script from "./df-button.vue?vue&type=script&lang=js"
export * from "./df-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.24.0__css-loader@6.8.1_webpack@5.8_5ruepoifw22n66m4srxffyekle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports