<template>
  <button
    :class="className"
    :type="type"
    :disabled="shouldDisable"
    v-on="$listeners"
  >
    <slot />

    <slot name="icon"></slot>
    <loader-component inline v-if="loading" :style="{['--_width']: '14px', ['--_height']: '2px' }" class="bg-current" />
  </button>
</template>

<script>
import LoaderComponent from '@/components/ui/loader-component.vue';

export default {
  name: 'DfButton',
  components: {
    LoaderComponent
  },
  props: {
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    sizes: {
      type: String,
      default: 'md'
    },
    customClass: {
      type: String,
      default: ''
    },
    removeWhitespaceWrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldDisable() {
      return this.loading || this.disabled;
    },
    className() {
      const baseStyle = [
        'flex justify-center items-center transition-ease-out transition-all duration-400 py-2 leading-5 text-sm gap-2 font-normal rounded-md',
        !this.removeWhitespaceWrap && 'whitespace-nowrap'
      ]
        .filter(Boolean)
        .join(' ');

      const variantClasses = {
        primary: [
          this.sizes === 'md' ? 'px-6' : '',
          this.shouldDisable
            ? 'text-gray-500 bg-gray-100 cursor-not-allowed'
            : 'bg-brand hover:bg-brand/70 text-brand-foreground'
        ],
        outlined: [
          this.sizes === 'md' ? 'px-6' : '',
          this.shouldDisable
            ? 'border border-gray-500 bg-transparent text-gray-900'
            : 'border border-brand hover:border-info-hover bg-transparent text-brand  hover:border-primary hover:text-primary'
        ],
        ['destructive-outlined']: [
          this.sizes === 'md' ? 'px-6' : '',
          this.shouldDisable
            ? 'border border-gray-500 bg-transparent text-gray-900'
            : 'text-destructive border-destructive border bg-transparent hover:bg-destructive/5'
        ],
        text: [
          'px-0',
          this.shouldDisable
            ? 'text-black-100'
            : 'underline hover:no-underline text-gray-600 hover:text-indigo-600 text-sm font-medium'
        ],
        base: [
          this.sizes === 'md' ? 'px-6' : '',
          this.shouldDisable
            ? 'bg-muted text-muted-foreground'
            : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
        ]
      };

      const variantStyle = variantClasses[this.variant] || [];
      const combinedClasses = [baseStyle, ...variantStyle, this.customClass];

      return combinedClasses.join(' ').trim();
    }
  }
};
</script>
